import React from "react";
import { Form, Alert, Container, Row, Col, Card, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import Layout from "components/Layout";
import "./ContactUs.scss";
import axios from "axios";

export default function ContactUs({ location }) {
  const [status, setStatus] = React.useState(0);
  const pathName = location.pathname.split("/")[1];

  const form = React.useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post("/.netlify/functions/sendMail", {
        name: form.current[0].value,
        address: form.current[1].value,
        city: form.current[2].value,
        email: form.current[3].value,
        phone: form.current[4].value,
        service_type: form.current[5].value,
        note: form.current[6].value,
      })
      .then(function (response) {
        // const responseBody = JSON.parse(response);
        if (response.data.success) {
          form.current[0].value = "";
          form.current[1].value = "";
          form.current[2].value = "Roseville";
          form.current[3].value = "";
          form.current[4].value = "";
          form.current[5].value = "Chemical Only";
          form.current[6].value = "";

          setStatus(1);
        }
      })
      .catch(function (error) {
        setStatus(-1);
        console.log(error);
      });
  };

  return (
    <Layout pathName={pathName}>
      <Container>
        {status === 1 && <Alert variant="success">Service request sent successfully!</Alert>}

        {status === -1 && (
          <Alert variant="danger">Whoops! Something went wrong. Try again later.</Alert>
        )}

        <Card border="primary" className="contact-us">
          <Card.Header className="card-header-custom">Contact Us</Card.Header>
          <Card.Body className="contact-body">
            <Form className="form-horizontal" validate="true" onSubmit={handleSubmit} ref={form}>
              <Form.Group as={Row} controlId="formPlaintextName">
                <Form.Label column sm={2} className="control-label">
                  Name <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="John Doe"
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPlaintextAddress">
                <Form.Label column sm={2} className="control-label">
                  Address
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="1003 Topaz Ct."
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formSelectCity">
                <Form.Label column sm={2} className="control-label">
                  City <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={10}>
                  <Form.Control as="select" custom name="city" required>
                    <option value="">Select City</option>
                    <option value="Roseville">Roseville</option>
                    <option value="Rocklin">Rocklin</option>
                    <option value="Granite Bay">Granite Bay</option>
                    <option value="Other">Other</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm={2} className="control-label">
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="john@example.com"
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPlaintextPhone">
                <Form.Label column sm={2} className="control-label">
                  Phone
                </Form.Label>
                <Col sm={10}>
                  <Form.Control type="text" name="phone" placeholder="123-456-7890" />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formSelectServiceType">
                <Form.Label column sm={2} className="control-label">
                  Service Type <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={10}>
                  <Form.Control as="select" custom name="service_type" required>
                    <option value="">Select Service Level</option>
                    <option value="Chemical Only">Chemical Only</option>
                    <option value="Chemical Plus">Chemical Plus</option>
                    <option value="Chemical Plus and Brush">Chemical Plus and Brush</option>
                    <option value="Full Clean">Full Clean</option>
                    <option value="Custom">Custom</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPlaintextNotes">
                <Form.Label column sm={2} className="control-label">
                  Notes <span className="text-danger">*</span>
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as="textarea"
                    name="note"
                    className="contact-text"
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm={{ span: 10, offset: 2 }}>
                  <Button type="submit" className="btn-default">
                    Send Message
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>

        <Row>
          <Col xs={12} className="text-center">
            Please contact us by calling us at 916-791-1221. Or you can email us your service
            request at{" "}
            <a href="mailto:service@rosevillepoolservice.com">service@rosevillepoolservice.com</a>.
          </Col>
        </Row>
      </Container>
    </Layout >
  );
}

ContactUs.propTypes = {
  location: PropTypes.object,
};

ContactUs.defaultProps = {
  location: null,
};
